import "./_levels.css";
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import GainRolesLevels from "../../components/gainRolesLevels/gainRolesLevels.jsx";
import * as levelApi from "../../utils/API/levelsAPI"
import LevelSqueleton from "../../components/levels/levelsSqueleton";
import Level from "../../components/levels/level";
import { useTranslation } from "react-i18next";

export const Levels = () => {
  const { t } = useTranslation();
  const [levels, setLevels] = useState([]);
  const [levelsRole, setLevelsRole] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(true)
  const [errorLoading, setErrorLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    let guildId = new URLSearchParams(window.location.search).get('id') || ""

    try {
      let result = await (!guildId ? levelApi.global(page) : levelApi.guild(guildId, page || 0))
      setHasMoreData((guildId ? result.rank : result).length !== 0)
      setPage(page + 1)
      setLevels(levels.concat(guildId ? result.rank : result))
      setLevelsRole(result.levelsRole)
      setLoading(false)
    } catch (error) {
      setErrorLoading(true)
    }
  };


  return (
    <div className="leaderboardglobal">
      <div className="top"><h1>{t("title.levels")}</h1><div className="search search-bar" data-v-7085cbe2=""></div></div>

      <div className="leaderboard">
        <div className="leaderboardLevel">
          <InfiniteScroll

            dataLength={levels.length}
            next={() => getData()}
            hasMore={hasMoreData}
            loader={<LevelSqueleton index={levels?.length || 0} />} >

            {(() => {
              var rank = [];

              if (levels.length === 0 && !loading) {
                return (
                  <div className="noLevelData">
                    <svg width="317" height="450" viewBox="0 0 980 1388" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M309.932 726C304.561 726 299.601 728.871 296.925 733.528L223.911 860.613C218.166 870.613 225.384 883.086 236.917 883.086H732.207C743.74 883.086 750.958 870.613 745.213 860.613L672.199 733.528C669.524 728.871 664.563 726 659.193 726H309.932Z" fill="#FFFDFD" />
                      <g filter="url(#filter0_d_901_545)">
                        <path d="M238.033 882.569C248.572 883.329 365.255 882.569 365.255 882.569V798.969V795.929V794.409L279.436 747.289C279.436 747.289 222.977 836.969 219.213 853.689C215.448 870.409 227.493 881.809 238.033 882.569Z" fill="black" />
                        <path d="M365.255 795.929V798.969M365.255 798.969V882.569C365.255 882.569 248.572 883.329 238.033 882.569C227.493 881.809 215.448 870.409 219.213 853.689C222.977 836.969 279.436 747.289 279.436 747.289L365.255 794.409V798.969Z" stroke="black" stroke-width="2" />
                      </g>
                      <g filter="url(#filter1_d_901_545)">
                        <path d="M727.888 882.569H600.665V798.969V795.929V794.409L686.484 747.289C686.484 747.289 742.943 836.969 746.708 853.689C750.473 870.409 744.541 882.569 727.888 882.569Z" fill="black" />
                        <path d="M600.665 795.929V798.969M600.665 798.969V882.569C600.665 882.569 711.235 882.569 727.888 882.569C744.541 882.569 750.473 870.409 746.708 853.689C742.943 836.969 686.484 747.289 686.484 747.289L600.665 794.409V798.969Z" stroke="black" stroke-width="2" />
                      </g>
                      <g filter="url(#filter2_d_901_545)">
                        <path d="M643.807 744.209L713.78 771.953L562.191 855.169L462.067 771.953L643.807 744.209Z" fill="url(#paint0_linear_901_545)" />
                      </g>
                      <g filter="url(#filter3_d_901_545)">
                        <path d="M326.185 744.209L256.211 771.953L407.8 855.169L507.924 771.953L326.185 744.209Z" fill="url(#paint1_linear_901_545)" />
                      </g>
                      <g filter="url(#filter4_d_901_545)">
                        <g filter="url(#filter5_d_901_545)">
                          <path d="M566.013 1013.89L484.492 1064.76L475.242 1058.96L462.032 1050.74L444.436 1039.8L431.282 1031.58L413.734 1020.61L402.96 1013.89L404.014 1010L411.542 981.8L417.168 960.672L424.705 932.439L430.332 911.312L437.859 883.111L443.486 861.958L451.023 833.757L454.26 820.236H514.714L516.671 828.976L521.865 848.43L525.779 863.007L530.973 882.493L534.84 897.102L540.034 916.557L543.949 931.165L549.142 950.62L553.047 965.196L558.194 984.683L562.108 999.292L566.013 1013.89Z" fill="#940C03" />
                        </g>
                        <g filter="url(#filter6_d_901_545)">
                          <path d="M468.183 820.236L449.84 837.702L453.205 820.236H468.183Z" fill="#890D05" />
                        </g>
                        <g filter="url(#filter7_d_901_545)">
                          <path d="M515.059 827.716L438.628 882.908L444.084 861.342L500.997 820.236H513.161L515.059 827.716Z" fill="#930C03" />
                        </g>
                        <g filter="url(#filter8_d_901_545)">
                          <path d="M525.253 862.592L425.383 932.222L430.942 911.034L521.385 847.975L525.253 862.592Z" fill="#890D05" />
                        </g>
                        <g filter="url(#filter9_d_901_545)">
                          <path d="M534.424 896.539L412.135 981.538L417.715 960.335L530.589 881.879L534.424 896.539Z" fill="#E7A04E" />
                        </g>
                        <g filter="url(#filter10_d_901_545)">
                          <path d="M544.612 932.262L413.784 1020.58L402.96 1013.95L404.019 1010.1L540.679 917.838L544.612 932.262Z" fill="#E7A04E" />
                        </g>
                        <g filter="url(#filter11_d_901_545)">
                          <path d="M552.765 965.492L447.322 1041.13L434.552 1032.8L548.974 950.715L552.765 965.492Z" fill="#E7A04E" />
                        </g>
                        <g filter="url(#filter12_d_901_545)">
                          <path d="M561.939 1000.4L477.906 1060.65L465.126 1052.35L558.152 985.648L561.939 1000.4Z" fill="#E7A04E" />
                        </g>
                        <g filter="url(#filter13_d_901_545)">
                          <path d="M527.291 799.073L516.345 827.427H452.624L441.688 799.073L484.489 754.48L527.291 799.073Z" fill="#9C0C02" />
                        </g>
                      </g>
                      <path d="M853.511 525.341C902.931 505.456 926.883 449.269 906.996 399.85C887.111 350.435 830.923 326.498 781.506 346.38C732.083 366.265 708.13 422.457 728.021 471.878C747.909 521.291 804.096 545.224 853.511 525.341Z" fill="url(#paint2_linear_901_545)" />
                      <path d="M65.6184 489.699C101.85 529.117 163.176 531.703 202.599 495.477C242.032 459.241 244.618 397.896 208.373 358.471C172.14 319.06 110.819 316.477 71.3992 352.7C31.9687 388.933 29.3798 450.273 65.6184 489.699Z" fill="url(#paint3_linear_901_545)" />
                      <g filter="url(#filter14_d_901_545)">
                        <path d="M816.009 662.527C708.084 823.479 267.083 830.795 144.523 662.527C21.9637 492.43 247.595 164.804 478.08 172.12C708.566 181.266 922.106 503.404 816.009 662.527Z" fill="url(#paint4_linear_901_545)" />
                      </g>
                      <rect x="520" y="378" width="224.113" height="223.123" rx="111.562" fill="white" />
                      <path d="M732.979 452.153C732.979 410.801 700.02 378 658.489 378C617.623 378 584 411.465 584 452.153C584 492.842 617.623 526.306 658.489 526.306C700.02 526.306 732.979 492.842 732.979 452.153Z" fill="black" />
                      <rect x="209" y="378" width="224.113" height="223.123" rx="111.562" fill="white" />
                      <path d="M416.979 452.153C416.979 410.801 384.02 378 342.489 378C301.623 378 268 411.465 268 452.153C268 492.842 301.623 526.306 342.489 526.306C384.02 526.306 416.979 492.842 416.979 452.153Z" fill="black" />
                      <g filter="url(#filter15_d_901_545)">
                        <g filter="url(#filter16_d_901_545)">
                          <path d="M226.085 638.366C226.085 638.366 383.375 785.946 382.375 791.3C381.375 796.654 362.875 818.149 355.349 816.231C347.803 814.316 213.366 650.089 213.366 650.089L226.085 638.366Z" fill="#A96551" />
                          <path d="M194.868 546.496C194.868 546.496 175.597 592.693 122.759 613.8L194.666 694.805C194.666 694.805 219.336 641.954 266.782 627.49L194.868 546.496Z" fill="#97503D" />
                          <path d="M185.862 544.84C185.862 544.84 202.016 519.177 213.101 526.006C224.186 532.827 291.821 601.948 288.791 611.263C285.761 620.578 269.588 639.135 263.803 635.462C258.026 631.779 195.59 564.278 185.862 544.84Z" fill="#A96551" />
                          <path d="M99.2689 626.96C99.2689 626.96 115.424 601.306 126.508 608.127C137.592 614.947 205.228 684.068 202.198 693.383C199.159 702.699 182.987 721.265 177.21 717.582C171.432 713.899 108.998 646.407 99.2689 626.96Z" fill="#A96551" />
                        </g>
                      </g>
                      <g filter="url(#filter17_di_901_545)">
                        <path d="M227.893 945.142L150.069 893.431C135.26 883.59 130.632 867.459 138.654 853.641L146.886 839.461C148.684 836.364 151.066 833.483 153.956 830.911L201.963 788.179L268.967 732.676C285.871 718.674 314.287 717.714 332.442 730.532L373.16 759.279C388.117 769.838 391.794 786.854 382.109 800.688L323.217 884.805L276.015 941.018C265.252 953.836 241.842 955.842 227.893 945.142Z" fill="#D9D9D9" />
                        <path d="M227.893 945.142L150.069 893.431C135.26 883.59 130.632 867.459 138.654 853.641L146.886 839.461C148.684 836.364 151.066 833.483 153.956 830.911L201.963 788.179L268.967 732.676C285.871 718.674 314.287 717.714 332.442 730.532L373.16 759.279C388.117 769.838 391.794 786.854 382.109 800.688L323.217 884.805L276.015 941.018C265.252 953.836 241.842 955.842 227.893 945.142Z" fill="black" />
                        <path d="M261.701 738.781C268.152 714.759 280.074 698.175 292.215 687.754C304.355 677.332 317.167 687.04 317.215 698.254C317.231 701.9 316.352 704.513 315.159 706.379C333.524 696.5 354.71 710.713 346.396 721.207C377.632 693.31 393.21 733.741 381.705 745.934C399.403 736.997 412.715 761.787 403.423 773.514C394.132 785.242 371.775 815.007 371.775 815.007L261.701 738.781Z" fill="url(#paint5_linear_901_545)" />
                      </g>
                      <path d="M631.186 1265.57C626.905 1267.79 628.812 1271.18 625.761 1284.92C622.794 1298.21 621.565 1306.97 621.437 1311.7C621.31 1316.38 622.158 1314.77 621.226 1318.24C620.293 1321.72 622.2 1332.66 622.667 1334.64C623.175 1336.58 625.294 1342.32 635.551 1346.72C645.765 1351.08 653.988 1361.83 662.295 1367.93C670.517 1373.95 693.277 1384.61 725.827 1377.62C759.013 1370.51 761.684 1370.11 765.244 1368.09C768.846 1366.11 771.008 1357.43 770.245 1353.47C769.524 1349.43 767.744 1349.19 767.617 1347.33C767.49 1345.43 763.76 1334.2 757.7 1330.93C751.596 1327.61 748.926 1319.21 745.408 1315.33C741.806 1311.46 733.753 1306 729.557 1304.15C725.361 1302.29 709.086 1280.96 706.204 1275.18C703.322 1269.28 697.049 1261.97 690.056 1264.72C683.063 1267.47 671.662 1266.66 666.703 1266.94C661.786 1267.22 658.819 1267.99 652.843 1264.72C646.867 1261.41 631.186 1265.57 631.186 1265.57Z" fill="#111010" />
                      <path d="M752.147 1355.2C751.639 1355.29 751.088 1355.37 750.579 1355.45C751.427 1355.29 751.342 1355.33 750.367 1355.49C749.604 1355.65 748.799 1355.77 748.036 1355.93C746.468 1356.3 744.9 1356.62 743.374 1357.02C740.28 1357.83 737.228 1358.68 734.177 1359.53C727.395 1361.39 720.656 1363.16 713.748 1363.97C700.482 1365.51 687.004 1363.08 675.561 1358.03C664.075 1352.9 655.217 1344.74 645.257 1337.23C643.858 1336.18 642.459 1335.17 641.018 1334.16C640.383 1333.71 639.747 1333.31 639.111 1332.91C638.857 1332.7 638.687 1332.62 638.602 1332.54C638.475 1332.46 638.348 1332.38 638.221 1332.34C635.763 1330.85 633.135 1329.68 630.422 1328.5C627.286 1327.17 624.15 1325.8 621.141 1324.18C621.48 1328.46 622.37 1333.39 622.666 1334.64C623.175 1336.58 625.294 1342.32 635.551 1346.72C645.765 1351.08 653.988 1361.83 662.295 1367.93C670.517 1373.95 693.277 1384.61 725.827 1377.62C759.013 1370.51 761.683 1370.11 765.244 1368.09C768.677 1366.19 770.796 1358.19 770.33 1353.99C764.269 1354.28 758.208 1354.4 752.147 1355.2Z" fill="#111010" />
                      <path d="M658.438 1353.8C666.279 1359.78 673.061 1363.17 681.876 1365.71C700.525 1371.09 723.92 1366.12 733.33 1363.98C739.178 1362.64 747.909 1360.54 760.412 1359.17C763.125 1358.89 767.321 1358.85 770.076 1358.69C770.33 1357.56 770.245 1357.8 770.33 1356.75C768.38 1356.83 766.388 1356.99 764.439 1357.15C755.962 1357.68 746.595 1358.97 738.288 1361.43C683.614 1377.47 659.54 1351.98 646.105 1343.05C642.799 1340.83 630.55 1332.39 627.541 1330.57C625.676 1329.48 623.599 1328.75 621.565 1327.99C621.777 1329.68 621.734 1329 621.946 1330.25C626.651 1331.42 651.148 1348.18 658.438 1353.8Z" fill="#45474D" />
                      <path d="M727.648 1291.46C713.449 1245.01 655.3 1112.96 623.851 988.988L542.009 1004.7L617.367 1325.27C652.714 1318.16 699.209 1297.16 727.648 1291.46Z" fill="#767A83" />
                      <path d="M401.892 1270.71C399.689 1272.65 359.382 1299.8 344.336 1307.39C329.29 1314.94 326.069 1318.01 325.518 1319.43C324.924 1320.8 317.465 1321.49 307.632 1322.5C292.544 1324.03 285.466 1337.48 285.466 1337.89C285.466 1338.29 285.211 1351.74 285.466 1352.43C285.762 1353.16 310.345 1363.42 333.189 1368.83C366.248 1376.67 428.255 1363.5 437.622 1362.93C437.622 1362.93 457.542 1362.53 468.688 1359.46C479.835 1356.35 484.158 1357.2 484.455 1351.06C484.709 1344.88 484.158 1336.07 483.353 1335.5C482.505 1334.94 482.505 1333.97 481.954 1327.79C481.403 1321.65 480.429 1313.97 479.708 1311.71C479.03 1309.45 479.157 1311.43 479.03 1308.2C478.86 1304.97 479.454 1297.57 476.656 1293.94C473.859 1290.3 468.434 1289.29 464.662 1291.96C460.89 1294.62 455.168 1301.65 446.098 1302.34C437.071 1303.03 424.779 1299.39 420.88 1293.37C416.981 1287.35 407.784 1273.78 405.834 1274.06C403.884 1274.35 401.892 1270.71 401.892 1270.71Z" fill="#111010" />
                      <path d="M483.353 1335.49C482.929 1335.21 482.717 1334.81 482.505 1333.64C471.91 1335.49 461.356 1337.6 450.887 1340.02C436.265 1343.33 421.685 1345.96 406.809 1347.69C392.187 1349.39 377.395 1351.57 362.646 1352.14C337.3 1353.11 310.302 1348.91 287.966 1336.34C285.339 1334.85 285.466 1337.47 285.466 1337.88C285.466 1338.28 284.872 1352.02 285.466 1352.42C297.969 1361.51 310.345 1363.41 333.189 1368.82C366.248 1376.66 428.255 1363.49 437.622 1362.92C437.622 1362.92 457.542 1362.52 468.688 1359.45C479.835 1356.34 484.158 1357.19 484.455 1351.05C484.709 1344.87 484.158 1336.06 483.353 1335.49Z" fill="#111010" />
                      <path d="M310.514 1351.94C319.923 1354.49 329.587 1356.43 339.335 1357.48C359.933 1359.7 380.362 1358.65 400.791 1355.7C421.982 1352.67 477.631 1341.2 484.37 1340.27C484.286 1338.33 484.031 1337.48 483.904 1337.24C476.572 1338.49 392.102 1363.45 332.893 1354.77C317.041 1352.43 299.579 1348.1 285.423 1339.94C285.381 1340.67 285.339 1341.6 285.339 1342.57C292.713 1347.18 302.165 1349.68 310.514 1351.94Z" fill="#45474D" />
                      <path d="M397.611 995.742C397.78 996.106 397.95 996.469 398.162 996.833C386.718 1119.96 375.359 1245.5 368.832 1291.79C396.509 1292.97 454.531 1315.47 488.904 1316.92L473.943 999.378L397.611 995.742Z" fill="#767A83" />
                      <path d="M741.72 924.404C748.713 913.982 761.852 899.804 743.161 894.351C724.512 888.857 702.176 922.142 702.176 922.142L741.72 924.404Z" fill="#D3A487" />
                      <path d="M916.083 1388H92.111V942.566H916.083V1388Z" fill="#925F45" />
                      <path d="M105.21 949.818H405.538C405.538 949.818 772.661 1355.87 894.428 1362.57C696.88 1363.5 108.389 1359.7 108.389 1359.7L105.21 949.818Z" fill="#80553E" />
                      <path d="M92.111 956.993H916.083V942.572H92.111V956.993Z" fill="#724B34" />
                      <path d="M885.228 980.39H668.904V980.471L664.496 976.189H889.636L885.228 980.471V980.39Z" fill="#BC8069" />
                      <path d="M668.905 1213.62H664.497V976.176H668.905V1213.62Z" fill="#442318" />
                      <path d="M664.496 1354.4L668.904 1350.11V1213.62C667.421 1212.29 665.98 1211 664.496 1209.66V1354.4Z" fill="#3C2016" />
                      <path d="M885.228 980.471L889.636 976.189V1354.4L885.228 1350.12V980.471Z" fill="#80553E" />
                      <path d="M862.426 1354.4H889.636L885.228 1350.12V1350.2H852.848C856.111 1351.73 859.332 1353.11 862.426 1354.4Z" fill="#442318" />
                      <path d="M664.497 1354.4H862.426C859.332 1353.11 856.111 1351.73 852.848 1350.2H668.904V1350.12L664.497 1354.4Z" fill="#3C2016" />
                      <path d="M878.618 986.684H675.518V986.846L668.906 980.464V980.383H885.23V980.464L878.618 986.846V986.684Z" fill="#A07053" />
                      <path d="M878.618 986.855L885.23 980.473V1350.12L878.618 1343.7V986.855Z" fill="#A07053" />
                      <path d="M675.518 1343.9H878.618V1343.69L885.23 1350.12V1350.2H668.906V1350.12L675.518 1343.69V1343.9Z" fill="#A07053" />
                      <path d="M675.516 986.855V1343.7L668.904 1350.12V980.473L675.516 986.855Z" fill="#A07053" />
                      <path d="M679.925 1339.7H874.21V1339.42L878.618 1343.7V1343.9H675.517V1343.7L679.925 1339.42V1339.7Z" fill="#BC8069" />
                      <path d="M878.618 1343.7V986.852L874.21 991.133V1339.42L878.618 1343.7Z" fill="#442318" />
                      <path d="M679.927 1339.42L675.519 1343.7V986.852L679.927 991.133V1339.42Z" fill="#80553E" />
                      <path d="M874.21 991.127L878.618 986.845V986.684H675.517V986.845L679.925 991.127V990.885H874.21V991.127Z" fill="#442318" />
                      <path d="M850.433 1015.98H701.626V1016.02L698.574 1013.07H853.442L850.433 1016.02V1015.98Z" fill="#BC8069" />
                      <path d="M701.625 1242.39V1016.02L698.574 1013.07V1239.77C699.591 1240.66 700.608 1241.55 701.625 1242.39Z" fill="#442318" />
                      <path d="M698.574 1323.1L701.625 1320.15V1242.39C700.608 1241.54 699.591 1240.65 698.574 1239.76V1323.1Z" fill="#3C2016" />
                      <path d="M850.433 1016.02L853.442 1013.07V1323.1L850.433 1320.15V1016.02Z" fill="#80553E" />
                      <path d="M701.626 1320.15V1320.19H850.433V1320.15L853.442 1323.1H698.574L701.626 1320.15Z" fill="#744E39" />
                      <path d="M845.857 1020.3H706.162V1020.42L701.627 1016.02V1015.98H850.434V1016.02L845.857 1020.42V1020.3Z" fill="#A07053" />
                      <path d="M845.857 1020.42L850.434 1016.01V1320.14L845.857 1315.74V1020.42Z" fill="#A07053" />
                      <path d="M706.162 1315.87H845.857V1315.74L850.434 1320.15V1320.19H701.627V1320.15L706.162 1315.74V1315.87Z" fill="#A07053" />
                      <path d="M706.159 1020.42V1315.74L701.624 1320.14V1016.01L706.159 1020.42Z" fill="#A07053" />
                      <path d="M709.171 1312.99H842.848V1312.79L845.857 1315.74V1315.86H706.162V1315.74L709.171 1312.79V1312.99Z" fill="#BC8069" />
                      <path d="M845.857 1315.74V1020.42L842.848 1023.37V1312.79L845.857 1315.74Z" fill="#442318" />
                      <path d="M709.171 1312.79L706.162 1315.74V1020.42L709.171 1023.37V1312.79Z" fill="#80553E" />
                      <path d="M842.848 1023.37L845.857 1020.42V1020.3H706.162V1020.42L709.171 1023.37V1023.16H842.848V1023.37Z" fill="#442318" />
                      <path d="M581.511 1015.98H432.661V1016.02L429.652 1013.07H584.521L581.511 1016.02V1015.98Z" fill="#BC8069" />
                      <path d="M429.651 1323.1L432.66 1320.15V1016.02L429.651 1013.07V1323.1Z" fill="#3C2016" />
                      <path d="M581.511 1016.02L584.521 1013.07V1323.1L581.511 1320.15V1016.02Z" fill="#80553E" />
                      <path d="M432.661 1320.15V1320.19H581.511V1320.15L584.521 1323.1H429.652L432.661 1320.15Z" fill="#744E39" />
                      <path d="M576.935 1020.3H437.24V1020.42L432.663 1016.02V1015.98H581.513V1016.02L576.935 1020.42V1020.3Z" fill="#A07053" />
                      <path d="M576.935 1020.42L581.513 1016.01V1320.14L576.935 1315.74V1020.42Z" fill="#A07053" />
                      <path d="M437.24 1315.87H576.935V1315.74L581.513 1320.15V1320.19H432.663V1320.15L437.24 1315.74V1315.87Z" fill="#A07053" />
                      <path d="M437.238 1020.42V1315.74L432.66 1320.14V1016.01L437.238 1020.42Z" fill="#A07053" />
                      <path d="M440.25 1312.99H573.926V1312.79L576.936 1315.74V1315.86H437.24V1315.74L440.25 1312.79V1312.99Z" fill="#BC8069" />
                      <path d="M576.936 1127.18V1020.42L573.926 1023.37V1124.23C574.944 1125.24 575.918 1126.21 576.936 1127.18Z" fill="#442318" />
                      <path d="M576.936 1315.74V1127.18C575.918 1126.21 574.944 1125.24 573.926 1124.23V1312.79L576.936 1315.74Z" fill="#3C2016" />
                      <path d="M440.25 1312.79L437.24 1315.74V1020.42L440.25 1023.37V1312.79Z" fill="#80553E" />
                      <path d="M576.936 1020.3V1020.42L573.926 1023.37V1023.16H440.25V1023.37L437.24 1020.42V1020.3H576.936Z" fill="#744E39" />
                      <path d="M311.487 1015.98H162.679V1016.02L159.67 1013.07H314.538L311.487 1016.02V1015.98Z" fill="#BC8069" />
                      <path d="M159.672 1323.1L162.682 1320.15V1016.02L159.672 1013.07V1323.1Z" fill="#3C2016" />
                      <path d="M311.487 1016.02L314.538 1013.07V1323.1L311.487 1320.15V1016.02Z" fill="#80553E" />
                      <path d="M162.679 1320.15V1320.19H311.487V1320.15L314.538 1323.1H159.67L162.679 1320.15Z" fill="#744E39" />
                      <path d="M306.952 1020.3H167.214V1020.42L162.679 1016.02V1015.98H311.487V1016.02L306.952 1020.42V1020.3Z" fill="#A07053" />
                      <path d="M306.952 1020.42L311.487 1016.01V1320.14L306.952 1315.74V1020.42Z" fill="#A07053" />
                      <path d="M167.214 1315.87H306.952V1315.74L311.487 1320.15V1320.19H162.679V1320.15L167.214 1315.74V1315.87Z" fill="#A07053" />
                      <path d="M167.215 1020.42V1315.74L162.68 1320.14V1016.01L167.215 1020.42Z" fill="#A07053" />
                      <path d="M170.267 1312.99H303.901V1312.79L306.953 1315.74V1315.86H167.215V1315.74L170.267 1312.79V1312.99Z" fill="#BC8069" />
                      <path d="M306.953 1315.74V1020.42L303.901 1023.37V1312.79L306.953 1315.74Z" fill="#3C2016" />
                      <path d="M170.267 1312.79L167.215 1315.74V1020.42L170.267 1023.37V1312.79Z" fill="#80553E" />
                      <path d="M303.901 1023.37L306.953 1020.42V1020.3H167.215V1020.42L170.267 1023.37V1023.16H303.901V1023.37Z" fill="#3C2016" />
                      <path d="M345.267 980.39H128.943V980.471L124.535 976.189H349.675L345.267 980.471V980.39Z" fill="#BC8069" />
                      <path d="M124.535 1354.4L128.943 1350.12V980.471L124.535 976.189V1354.4Z" fill="#3C2016" />
                      <path d="M345.267 980.471L349.675 976.189V1354.4L345.267 1350.12V980.471Z" fill="#80553E" />
                      <path d="M124.535 1354.4H349.675L345.267 1350.12V1350.2H128.943V1350.12L124.535 1354.4Z" fill="#3C2016" />
                      <path d="M338.657 986.684H135.514V986.846L128.945 980.464V980.383H345.269V980.464L338.657 986.846V986.684Z" fill="#A07053" />
                      <path d="M338.657 986.855L345.269 980.473V1350.12L338.657 1343.7V986.855Z" fill="#A07053" />
                      <path d="M135.514 1343.9H338.657V1343.69L345.269 1350.12V1350.2H128.945V1350.12L135.514 1343.69V1343.9Z" fill="#A07053" />
                      <path d="M135.511 986.855V1343.7L128.942 1350.12V980.473L135.511 986.855Z" fill="#A07053" />
                      <path d="M139.922 1339.7H334.249V1339.42L338.657 1343.7V1343.9H135.514V1343.7L139.922 1339.42V1339.7Z" fill="#BC8069" />
                      <path d="M338.657 1343.7V986.852L334.249 991.133V1339.42L338.657 1343.7Z" fill="#3C2016" />
                      <path d="M139.922 1339.42L135.514 1343.7V986.852L139.922 991.133V1339.42Z" fill="#80553E" />
                      <path d="M334.249 991.127L338.657 986.845V986.684H135.514V986.845L139.922 991.127V990.885H334.249V991.127Z" fill="#3C2016" />
                      <path d="M615.25 980.39H398.926V980.471L394.518 976.189H619.658L615.25 980.471V980.39Z" fill="#BC8069" />
                      <path d="M394.518 1354.4L398.926 1350.12V980.471L394.518 976.189V1354.4Z" fill="#3C2016" />
                      <path d="M615.25 980.471L619.658 976.189V1354.4L615.25 1350.12V980.471Z" fill="#80553E" />
                      <path d="M394.518 1354.4H619.658L615.25 1350.12V1350.2H398.926V1350.12L394.518 1354.4Z" fill="#3C2016" />
                      <path d="M608.636 986.684H405.535V986.846L398.923 980.464V980.383H615.247V980.464L608.636 986.846V986.684Z" fill="#A07053" />
                      <path d="M608.636 986.855L615.247 980.473V1350.12L608.636 1343.7V986.855Z" fill="#A07053" />
                      <path d="M405.535 1343.9H608.636V1343.69L615.247 1350.12V1350.2H398.923V1350.12L405.535 1343.69V1343.9Z" fill="#A07053" />
                      <path d="M405.538 986.855V1343.7L398.926 1350.12V980.473L405.538 986.855Z" fill="#A07053" />
                      <path d="M409.943 1339.7H604.227V1339.42L608.635 1343.7V1343.9H405.535V1343.7L409.943 1339.42V1339.7Z" fill="#BC8069" />
                      <path d="M608.635 1157.8V986.852L604.227 991.133V1153.6C605.711 1155.01 607.194 1156.39 608.635 1157.8Z" fill="#442318" />
                      <path d="M608.635 1343.7V1157.8C607.194 1156.39 605.711 1155.01 604.227 1153.6V1339.41L608.635 1343.7Z" fill="#3C2016" />
                      <path d="M409.944 1339.42L405.536 1343.7V986.852L409.944 991.133V1339.42Z" fill="#80553E" />
                      <path d="M604.227 991.127L608.635 986.845V986.684H439.611C440.882 988.057 442.196 989.43 443.553 990.885H604.227V991.127Z" fill="#442318" />
                      <path d="M409.946 991.127V990.885H443.555C442.199 989.43 440.885 988.057 439.614 986.684H405.538V986.845L409.946 991.127Z" fill="#3C2016" />
                      <path d="M52.4826 942.586H955.754V875.41H52.4826V942.586Z" fill="#BC8069" />
                      <path d="M70.3698 881.859H372.223L643.01 934.534L76.0068 936.069L70.3698 881.859Z" fill="#A97463" />
                      <path d="M885.229 875.404H900.571V371.238H885.229V875.404Z" fill="#A56131" />
                      <path d="M892.349 379.355C901.419 379.638 909.006 372.892 909.345 364.247C909.684 355.603 902.563 348.332 893.493 348.009C884.423 347.726 876.837 354.472 876.498 363.116C876.159 371.761 883.279 379.032 892.349 379.355Z" fill="#A56131" />
                      <path d="M876.161 796.553C871.245 801.481 862.174 804.591 842.848 802.087C853.571 697.99 887.35 425.972 884.171 385.416C898.751 391.192 900.701 385.416 900.701 385.416L955.587 757.733C955.587 757.733 945.881 785.323 906.041 775.709" fill="#8E2729" />
                      <path d="M905.745 775.709C879.128 769.286 885.867 786.575 875.907 796.553L884.172 416.924L905.745 775.709Z" fill="#6C2121" />
                      <path d="M139.878 875.196H249.1V869.379H139.878V875.196Z" fill="#362531" />
                      <path d="M143.736 869.379H245.202C245.202 864.128 240.752 859.846 235.242 859.846H153.739C148.229 859.846 143.736 864.128 143.736 869.379Z" fill="#433542" />
                      <path d="M9 909.172H980V896.084H9V909.172Z" fill="#C98D7B" />
                      <path d="M266.163 877.058L477.112 876.893C477.112 876.893 477.615 876.646 477.917 876.893H479.275V878.005C484.758 881.96 491.9 882.742 496.024 881.713V885.338C488.178 885.42 481.69 884.225 477.112 881.177H266.163C264.956 881.177 264 880.271 264 879.117C264 877.964 264.956 877.058 266.163 877.058Z" fill="#302C51" />
                      <path d="M479.275 880.604V879.451L266.163 879.616C265.509 879.616 264.905 879.945 264.503 880.398C264.201 880.027 264 879.574 264 879.08C264 877.927 264.956 876.979 266.163 876.979L479.275 876.814V877.968C484.758 881.881 491.9 882.705 496.024 881.634V884.27C491.447 884.27 484.758 884.518 479.275 880.604Z" fill="#502D76" />
                      <path d="M479.275 879.121V878.009L266.163 878.174C265.157 878.174 264.352 878.792 264.101 879.657C264.05 879.451 264 879.286 264 879.08C264 877.927 264.956 876.979 266.163 876.979L479.275 876.814V877.968C484.758 881.881 491.85 881.634 496.024 881.634V882.829C491.9 882.829 484.758 883.035 479.275 879.121Z" fill="#6E55A6" />
                      <path d="M725.837 877.058L514.888 876.893C514.888 876.893 514.385 876.646 514.133 876.893H512.725V878.005C507.242 881.96 500.1 882.742 496.026 881.713V885.338C503.822 885.42 510.311 884.225 514.888 881.177H725.837C727.044 881.177 728 880.271 728 879.117C728 877.964 727.044 877.058 725.837 877.058Z" fill="#302C51" />
                      <path d="M512.725 880.604V879.451L725.837 879.616C726.491 879.616 727.095 879.945 727.497 880.398C727.799 880.027 728 879.574 728 879.08C728 877.927 727.044 876.979 725.837 876.979L512.725 876.814V877.968C507.242 881.881 500.1 882.705 496.026 881.634V884.27C500.402 884.27 507.242 884.518 512.725 880.604Z" fill="#502D76" />
                      <path d="M512.725 879.121V878.009L725.837 878.174C726.843 878.174 727.648 878.792 727.899 879.657C727.95 879.451 728 879.286 728 879.08C728 877.927 727.044 876.979 725.837 876.979L512.725 876.814V877.968C507.242 881.881 500.201 881.716 496.026 881.634V882.829C500.352 882.829 507.242 883.035 512.725 879.121Z" fill="#6E55A6" />
                      <path d="M677.303 878.161L560.674 852.212L528.062 858.162L507.58 869.499L498.333 873.19L489.087 870.968L457.32 853.944L423.763 850.818L336.664 864.49C336.664 864.49 299.13 878.161 300.721 878.161C302.262 878.199 477.951 876.73 477.951 876.73C477.951 876.73 479.33 877.709 484.159 880.082C488.988 882.455 503.473 882.455 508.302 880.082C513.131 877.709 520.158 876.73 520.158 876.73L677.303 878.161Z" fill="#502D76" />
                      <path d="M477.369 876.469C477.369 876.469 491.201 874.739 496.281 874.368C495.878 860.939 475.558 833.999 443.267 835.029C410.975 836.059 341.313 863.699 295.994 859.538C289.456 869.548 271.7 876.469 271.7 876.469C271.7 876.469 334.271 879.847 362.388 872.679C388.191 866.047 437.482 857.479 450.51 859.909C463.587 862.298 477.369 876.469 477.369 876.469Z" fill="#B09878" />
                      <path d="M443.267 837.838C410.975 838.868 341.313 863.707 295.994 859.588C289.506 869.556 271.751 876.436 271.7 876.477C271.7 876.477 289.456 869.598 295.994 859.588C341.313 863.707 410.975 836.108 443.267 835.078C475.558 834.007 495.878 860.947 496.281 874.417C495.878 860.947 475.558 836.808 443.267 837.838Z" fill="#EADEA8" />
                      <path d="M484.36 868.026C484.36 868.026 483.606 867.161 482.147 865.802C480.688 864.484 478.576 862.548 475.809 860.529C474.451 859.499 472.892 858.47 471.232 857.357C469.573 856.328 467.812 855.215 465.85 854.227C463.939 853.197 461.927 852.249 459.815 851.343C457.752 850.478 455.539 849.613 453.326 848.913C451.113 848.213 448.85 847.677 446.586 847.224C445.43 846.977 444.323 846.812 443.216 846.606C442.06 846.482 440.953 846.359 439.897 846.235C438.79 846.194 437.734 846.07 436.678 846.029C435.571 846.029 434.565 846.029 433.559 846.029C432.553 846.112 431.598 846.153 430.642 846.194C429.686 846.276 428.781 846.4 427.926 846.482C426.165 846.688 424.606 847.018 423.198 847.265C421.84 847.636 420.633 847.883 419.677 848.171C417.766 848.789 416.659 849.16 416.659 849.16C416.659 849.16 417.766 848.872 419.727 848.46C420.733 848.213 421.94 848.048 423.298 847.801C424.003 847.677 424.757 847.595 425.562 847.512C426.316 847.43 427.121 847.306 428.026 847.265C428.881 847.224 429.787 847.142 430.692 847.1C431.598 847.059 432.604 847.059 433.559 847.059C434.565 847.1 435.571 847.142 436.577 847.183C437.633 847.265 438.69 847.347 439.746 847.43C440.802 847.553 441.909 847.718 443.015 847.842C444.071 848.048 445.178 848.254 446.285 848.46C448.498 848.954 450.711 849.531 452.874 850.231C455.087 850.89 457.199 851.673 459.312 852.497C461.374 853.362 463.386 854.309 465.247 855.257C469.07 857.234 472.49 859.252 475.306 861.147C478.173 863.042 480.437 864.772 481.946 866.008C483.505 867.244 484.36 868.026 484.36 868.026Z" fill="#9B856C" />
                      <path d="M394.88 859.746C394.88 859.746 393.522 859.993 391.158 860.652C388.844 861.27 385.525 862.218 381.501 863.289C377.527 864.401 372.85 865.554 367.769 866.708C366.512 866.996 365.255 867.284 363.947 867.573C362.639 867.861 361.331 868.108 359.973 868.397C357.307 868.973 354.591 869.385 351.875 869.838C349.159 870.333 346.393 870.703 343.727 871.115C341.011 871.404 338.395 871.774 335.78 871.98C333.215 872.104 330.7 872.434 328.336 872.475C325.972 872.557 323.708 872.722 321.646 872.681C319.584 872.681 317.673 872.681 315.963 872.639C314.303 872.639 312.844 872.434 311.637 872.392C309.172 872.228 307.814 872.145 307.814 872.145C307.814 872.145 309.172 872.351 311.587 872.681C312.794 872.804 314.252 873.051 315.963 873.134C317.622 873.257 319.534 873.34 321.646 873.463C323.708 873.546 325.972 873.505 328.386 873.505C330.75 873.505 333.265 873.299 335.881 873.175C338.496 873.01 341.162 872.639 343.928 872.392C346.594 872.022 349.36 871.651 352.127 871.198C354.843 870.703 357.609 870.25 360.275 869.632C361.583 869.344 362.941 869.056 364.249 868.767C365.506 868.479 366.814 868.149 368.071 867.861C370.636 867.284 373.051 866.543 375.314 865.925C377.628 865.266 379.791 864.648 381.752 864.03C385.726 862.836 388.945 861.682 391.259 860.899C392.415 860.529 393.321 860.199 393.924 859.993C394.528 859.828 394.88 859.746 394.88 859.746Z" fill="#9B856C" />
                      <path d="M515.143 876.469C515.143 876.469 501.311 874.739 496.281 874.368C496.633 860.939 516.953 833.999 549.245 835.029C581.536 836.059 651.198 863.699 696.517 859.538C703.056 869.548 720.811 876.469 720.811 876.469C720.811 876.469 658.24 879.847 630.124 872.679C604.321 866.047 555.029 857.479 542.002 859.909C528.924 862.298 515.143 876.469 515.143 876.469Z" fill="#B09878" />
                      <path d="M549.245 837.838C581.536 838.868 651.198 863.707 696.517 859.588C703.056 869.556 720.76 876.436 720.811 876.477C720.811 876.477 703.056 869.598 696.517 859.588C651.198 863.707 581.536 836.108 549.245 835.078C516.953 834.007 496.633 860.947 496.281 874.417C496.633 860.947 516.953 836.808 549.245 837.838Z" fill="#EADEA8" />
                      <path d="M508.154 868.026C508.154 868.026 508.909 867.161 510.367 865.802C511.826 864.484 513.938 862.548 516.705 860.529C518.113 859.499 519.622 858.47 521.282 857.357C522.942 856.328 524.702 855.215 526.664 854.227C528.575 853.197 530.587 852.249 532.7 851.343C534.812 850.478 536.975 849.613 539.188 848.913C541.401 848.213 543.715 847.677 545.928 847.224C547.085 846.977 548.191 846.812 549.298 846.606C550.455 846.482 551.561 846.359 552.618 846.235C553.724 846.194 554.831 846.07 555.887 846.029C556.943 846.029 557.949 846.029 558.955 846.029C559.961 846.112 560.967 846.153 561.872 846.194C562.828 846.276 563.733 846.4 564.588 846.482C566.349 846.688 567.908 847.018 569.316 847.265C570.675 847.636 571.882 847.883 572.837 848.171C574.799 848.789 575.906 849.16 575.906 849.16C575.906 849.16 574.749 848.872 572.787 848.46C571.781 848.213 570.574 848.048 569.216 847.801C568.512 847.677 567.757 847.595 567.003 847.512C566.198 847.43 565.393 847.306 564.538 847.265C563.633 847.224 562.778 847.142 561.822 847.1C560.917 847.059 559.961 847.059 558.955 847.059C557.949 847.1 556.943 847.142 555.937 847.183C554.881 847.265 553.825 847.347 552.768 847.43C551.712 847.553 550.606 847.718 549.499 847.842C548.443 848.048 547.336 848.254 546.23 848.46C544.017 848.954 541.804 849.531 539.641 850.231C537.478 850.89 535.315 851.673 533.253 852.497C531.14 853.362 529.128 854.309 527.267 855.257C523.445 857.234 520.025 859.252 517.208 861.147C514.391 863.042 512.077 864.772 510.569 866.008C509.009 867.244 508.154 868.026 508.154 868.026Z" fill="#9B856C" />
                      <path d="M597.634 859.746C597.634 859.746 598.992 859.993 601.356 860.652C603.67 861.27 606.99 862.218 611.013 863.289C614.987 864.401 619.715 865.554 624.745 866.708C626.002 866.996 627.31 867.284 628.567 867.573C629.875 867.861 631.233 868.108 632.541 868.397C635.207 868.973 637.923 869.385 640.639 869.838C643.355 870.333 646.121 870.703 648.787 871.115C651.503 871.404 654.169 871.774 656.734 871.98C659.299 872.104 661.814 872.434 664.178 872.475C666.542 872.557 668.806 872.722 670.868 872.681C672.93 872.681 674.842 872.681 676.552 872.639C678.212 872.639 679.67 872.434 680.877 872.392C683.342 872.228 684.7 872.145 684.7 872.145C684.7 872.145 683.342 872.351 680.928 872.681C679.72 872.804 678.262 873.051 676.552 873.134C674.892 873.257 672.981 873.34 670.868 873.463C668.806 873.546 666.542 873.505 664.128 873.505C661.764 873.505 659.249 873.299 656.634 873.175C654.018 873.01 651.352 872.639 648.636 872.392C645.92 872.022 643.154 871.651 640.387 871.198C637.671 870.703 634.905 870.25 632.239 869.632C630.931 869.344 629.573 869.056 628.266 868.767C627.008 868.479 625.7 868.149 624.443 867.861C621.878 867.284 619.514 866.543 617.2 865.925C614.937 865.266 612.774 864.648 610.762 864.03C606.788 862.836 603.569 861.682 601.256 860.899C600.099 860.529 599.244 860.199 598.59 859.993C597.986 859.828 597.634 859.746 597.634 859.746Z" fill="#9B856C" />
                      <rect x="398.267" y="601" width="153.427" height="38.4963" rx="9" fill="#532727" />
                      <rect x="419" y="632" width="117" height="32" rx="9" fill="#532727" />
                      <path d="M564.878 590.804C571.021 590.721 576.16 595.645 575.375 601.739C574.378 609.473 572.197 617.071 568.876 624.328C563.977 635.03 556.695 644.773 547.453 652.993C538.21 661.213 527.192 667.745 515.037 672.21C502.883 676.674 489.835 678.982 476.652 679C463.469 679.018 450.413 676.744 438.243 672.312C426.073 667.88 415.032 661.378 405.761 653.182C396.49 644.987 389.174 635.263 384.238 624.574C380.892 617.33 378.685 609.74 377.661 602.012C376.854 595.917 381.98 590.974 388.128 591.041C394.304 591.108 399.147 596.222 400.222 602.305C401.126 607.422 402.703 612.444 404.931 617.268C408.76 625.56 414.435 633.103 421.627 639.46C428.818 645.817 437.383 650.861 446.824 654.299C456.264 657.737 466.391 659.501 476.618 659.487C486.844 659.473 496.966 657.683 506.394 654.22C515.822 650.756 524.369 645.689 531.539 639.313C538.708 632.937 544.357 625.379 548.157 617.077C550.367 612.25 551.926 607.227 552.812 602.11C553.868 596.021 558.698 590.888 564.878 590.804Z" fill="black" />
                      <rect x="383.678" y="591.4" width="185.644" height="16.8" rx="5" fill="black" />
                      <path d="M465.721 72.1191C465.721 69.3577 467.959 67.1191 470.721 67.1191H489.849C492.61 67.1191 494.849 69.3577 494.849 72.1191V189.399C494.849 192.16 492.61 194.399 489.849 194.399H470.721C467.959 194.399 465.721 192.16 465.721 189.399V72.1191Z" fill="var(--color-principal-hover)" />
                      <path d="M439.988 113.071C460.211 135.076 494.444 136.521 516.45 116.299C538.461 96.0717 539.903 61.8283 519.67 39.823C499.445 17.8274 465.219 16.3871 443.217 36.6051C421.21 56.8285 419.763 91.0648 439.988 113.071Z" fill="url(#paint6_linear_901_545)" />
                      <path d="M364.457 294.931C371.701 295.595 377.637 288.371 377.637 281.811C377.637 274.587 371.701 269.335 364.457 268.69C326.226 265.41 287.995 273.942 253.709 291.651C238.557 299.539 251.737 322.499 266.889 314.631C297.212 298.875 330.834 292.315 364.457 294.931ZM686.119 314.631C701.271 322.499 714.451 300.183 699.299 291.651C665.676 273.942 626.781 265.41 589.214 268.69C581.97 269.335 576.035 274.587 576.035 281.811C576.035 288.371 581.97 295.595 589.214 294.931C622.837 292.315 656.46 298.875 686.119 314.631Z" fill="black" />
                      <path d="M508.798 651.389C506.742 661.78 474.883 660.743 460.537 657.904C446.191 655.064 414.3 641.928 416.356 631.538C418.413 621.148 453.638 617.439 467.984 620.278C482.33 623.117 510.855 640.999 508.798 651.389Z" fill="#C45F5F" />
                      <defs>
                        <filter id="filter0_d_901_545" x="151.507" y="683.959" width="280.749" height="269.949" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="33" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter1_d_901_545" x="533.665" y="683.959" width="281.131" height="269.611" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="33" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter2_d_901_545" x="362.067" y="648.209" width="451.713" height="310.961" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="50" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter3_d_901_545" x="156.211" y="648.209" width="451.713" height="310.961" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="50" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter4_d_901_545" x="336.96" y="692.48" width="295.053" height="442.279" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="33" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter5_d_901_545" x="336.96" y="758.236" width="295.053" height="376.523" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="33" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter6_d_901_545" x="445.84" y="820.236" width="26.3435" height="25.4668" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter7_d_901_545" x="434.628" y="820.236" width="84.4309" height="70.6719" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter8_d_901_545" x="421.383" y="847.975" width="107.87" height="92.248" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter9_d_901_545" x="408.135" y="881.879" width="130.29" height="107.658" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter10_d_901_545" x="398.96" y="917.838" width="149.652" height="110.74" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter11_d_901_545" x="430.552" y="950.715" width="126.213" height="98.4121" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter12_d_901_545" x="461.126" y="985.648" width="104.812" height="83" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter13_d_901_545" x="341.688" y="658.48" width="285.603" height="272.945" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="50" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter14_d_901_545" x="46" y="112" width="862" height="742" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="32" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter15_d_901_545" x="33.2689" y="462.85" width="415.111" height="423.502" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="33" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter16_d_901_545" x="33.2689" y="462.85" width="415.111" height="423.502" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="33" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                        </filter>
                        <filter id="filter17_di_901_545" x="70.7557" y="623.307" width="399.714" height="396.742" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="32" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_545" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_901_545" result="shape" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="33" />
                          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_901_545" />
                        </filter>
                        <linearGradient id="paint0_linear_901_545" x1="587.924" y1="744.209" x2="652.632" y2="806.354" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#B7BAB7" />
                          <stop offset="1" stop-color="#D3D4D3" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_901_545" x1="382.067" y1="744.209" x2="317.36" y2="806.354" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#B7BAB7" />
                          <stop offset="1" stop-color="#D3D4D3" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_901_545" x1="890.5" y1="375.5" x2="759.5" y2="479" gradientUnits="userSpaceOnUse">
                          <stop stop-color="var(--color-principal-hover)" />
                          <stop offset="1" stop-color="var(--color-principal)" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_901_545" x1="74.5" y1="348.5" x2="200.5" y2="491.5" gradientUnits="userSpaceOnUse">
                          <stop stop-color="var(--color-principal)" />
                          <stop offset="1" stop-color="var(--color-principal-hover)" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_901_545" x1="844" y1="556" x2="110" y2="547.5" gradientUnits="userSpaceOnUse">
                          <stop stop-color="var(--color-principal)" />
                          <stop offset="1" stop-color="var(--color-principal-hover)" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_901_545" x1="272.709" y1="716.773" x2="396.52" y2="810.939" gradientUnits="userSpaceOnUse">
                          <stop offset="0.0986931" stop-color="var(--color-principal-hover)" />
                          <stop offset="0.808424" stop-color="var(--color-principal)" />
                          <stop offset="0.989583" stop-color="var(--color-principal)" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_901_545" x1="524.317" y1="39.6792" x2="444.498" y2="102.746" gradientUnits="userSpaceOnUse">
                          <stop stop-color="var(--color-principal-hover)" />
                          <stop offset="1" stop-color="var(--color-principal)" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span>{t("levels.no_guild_leaderboard")}</span>
                  </div>

                )
              }

              for (let i = 0; i < levels.length; i++) {
                rank.push(<Level level={levels[i]} i={i} />);
              }

              return rank;
            })()}
          </InfiniteScroll>
        </div>

        {(() => {
          if (levelsRole && levelsRole.length > 0) {
            return (<GainRolesLevels levelsRole={levelsRole} />)
          }
        })()}

      </div>
    </div>
  )
}