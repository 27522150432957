import "./_login.css";
import Avatar from "../../components/avatar/avatar";
import { Toaster, toast } from 'sonner'
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const Login = () => {
    const { t } = useTranslation();

    useEffect(() => {
        let status = new URLSearchParams(window.location.search).get('status')

        if (status == "invalidToken") {
            toast.error(t("login.toast.sessionExpired"), {
                duration: 5000,
            })
        }
        else if (status == "error") {
            toast.error(t("login.toast.error"), {
                duration: 5000,
            })
        }

        return () => {
            toast.dismiss()
        }

    }, [])

    return (
        <div className="container-logo">

            <Avatar classElement="width-logo-svg" />
            <p><strong>{t("login.welcome")}</strong><br />{t("login.logyou")}</p>
            <a className="boutonConnection" href={process.env.REACT_APP_OAUTH2_LINK}>
                <svg width="22" height="22" style={{ marginRight: "20px" }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.624 5.39694C18.2218 4.75037 16.7181 4.27399 15.1459 4.00114C15.1173 3.99588 15.0887 4.00905 15.0739 4.03537C14.8805 4.38103 14.6664 4.83196 14.5163 5.18639C12.8254 4.93197 11.1431 4.93197 9.48679 5.18639C9.33677 4.82407 9.11478 4.38103 8.92053 4.03537C8.90578 4.00992 8.87716 3.99676 8.84855 4.00114C7.27726 4.27312 5.7736 4.7495 4.37053 5.39694C4.35838 5.40222 4.34798 5.41098 4.34104 5.42239C1.48894 9.70454 0.707629 13.8814 1.09091 18.0065C1.09265 18.0267 1.10392 18.0461 1.11953 18.0582C3.00127 19.4471 4.82405 20.2902 6.61301 20.849C6.64163 20.8577 6.67197 20.8472 6.69019 20.8236C7.11336 20.2429 7.49058 19.6304 7.81403 18.9864C7.8331 18.9487 7.8149 18.9041 7.77587 18.889C7.17754 18.661 6.6078 18.3829 6.05976 18.0671C6.0164 18.0416 6.01293 17.9793 6.05282 17.9495C6.16816 17.8626 6.28349 17.7722 6.39362 17.681C6.41357 17.6643 6.44131 17.6609 6.46472 17.6714C10.0652 19.3234 13.9631 19.3234 17.521 17.6714C17.5445 17.66 17.5721 17.6635 17.593 17.6802C17.7032 17.7714 17.8185 17.8626 17.9347 17.9495C17.9746 17.9793 17.972 18.0416 17.9287 18.0671C17.3806 18.389 16.8109 18.661 16.2116 18.8882C16.1726 18.9031 16.1553 18.9487 16.1744 18.9864C16.5047 19.6296 16.882 20.2419 17.2973 20.8226C17.3146 20.8472 17.3458 20.8577 17.3745 20.849C19.1722 20.2902 20.9949 19.4471 22.8766 18.0582C22.8931 18.0461 22.9036 18.0276 22.9052 18.0074C23.364 13.2383 22.137 9.09569 19.6526 5.42326C19.6465 5.41099 19.6361 5.40222 19.624 5.39694ZM8.35167 15.4948C7.26772 15.4948 6.37454 14.4947 6.37454 13.2664C6.37454 12.0382 7.25038 11.0381 8.35167 11.0381C9.46164 11.0381 10.3461 12.047 10.3288 13.2664C10.3288 14.4947 9.45297 15.4948 8.35167 15.4948ZM15.6619 15.4948C14.5779 15.4948 13.6847 14.4947 13.6847 13.2664C13.6847 12.0382 14.5606 11.0381 15.6619 11.0381C16.7718 11.0381 17.6563 12.047 17.639 13.2664C17.639 14.4947 16.7718 15.4948 15.6619 15.4948Z" fill="white" />
                </svg>
                <span>{t("login.loginwithdiscord")}</span>
            </a>


            <Toaster richColors expand={false} position="bottom-right" />
        </div>
    )
}